import React from "react";

import NavItem from "../NavItem";
import SummaryTitle from "../SummaryTitle";
import { Container, Collection, ItemText } from "./styled";
import {
  eventClickPhoneNumber,
  eventClickEmail,
} from "../../../services/analytics/ga4CustomEvents";

function ContactUs() {
  return (
    <Container>
      <SummaryTitle>Contact Us</SummaryTitle>
      <Collection>
        <ItemText>Corporate Office</ItemText>
        <ItemText>
          <NavItem
            href={
              "http://maps.google.com/?q=1251 S. Nelson Dr, Chandler, AZ, 85226"
            }
            name={"1251 S. Nelson Dr, Chandler, AZ, 85226 (external link to maps)"}
          />
        </ItemText>
      </Collection>
      <Collection>
        <ItemText
          onClick={() =>
            eventClickPhoneNumber(
              "18665802280",
              "Clicks on phone numbers in the Footer",
            )
          }
        >
          <NavItem name="866-580-2280" href="tel:18665802280" />
        </ItemText>
        <ItemText>Monday-Friday: 10am-5pm</ItemText>
        <ItemText>Saturday: 10am-2pm</ItemText>
        <ItemText>Sunday: Closed</ItemText>
      </Collection>
      <Collection>
        <ItemText
          onClick={() => eventClickEmail("appointments@classycompanies.com")}
        >
          <NavItem
            href={"mailto:appointments@classycompanies.com"}
            name={"appointments@classycompanies.com"}
          />
        </ItemText>
      </Collection>
    </Container>
  );
}

export default ContactUs;
