const arr = [
  {
    name: "intagram",
    imgSrc: "/icons/social_instagram_default.png",
    hoverImgSrc: "/icons/social_instagram_hover.png",
    label: "instagram",
    href: "https://www.instagram.com/classyclosetsus"
  },
  {
    name: "facebook",
    imgSrc: "/icons/social_facebook_default.png",
    hoverImgSrc: "/icons/social_facebook_hover.png",
    label: "facebook",
    href: "https://www.facebook.com/ClassyClosetsUS/"
  },
  {
    name: "youtube",
    imgSrc: "/icons/social_youtube_default.png",
    hoverImgSrc: "/icons/social_youtube_hover.png",
    label: "youtube",
    href: "https://www.youtube.com/Classyclosets"
  },
  {
    name: "pinterest",
    imgSrc: "/icons/social_pinterest_default.png",
    hoverImgSrc: "/icons/social_pinterest_hover.png",
    label: "pinterest",
    href: "https://www.pinterest.com/classyclosets/"
  },
]

export default arr;