import Image from "next/image";
import { ContainerMobile } from "./styled";

function LogoMobile() {
  return (
    <ContainerMobile>
      <Image
        src="/images/logo/classyclosets.svg"
        alt="Classy Closets Logo Mobile"
        layout="fill"
        objectFit="contain"
        // width={1200}
        // height={120}
        loading="lazy"
        quality={100}
      />
    </ContainerMobile>
  );
}

export default LogoMobile;
