import Image from "next/image";

function LogoSecondary() {
  return (
    <>
      <Image
        src="/images/logo/Closets-Logo.svg"
        alt="Classy Closets Logo"
        width={440}
        height={40}
        loading="lazy"
        quality={100}
      />
    </>
  );
}

export default LogoSecondary;
